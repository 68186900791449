const Front = () => import("../../views/Front.vue");
const Home = () => import("../../views/Home.vue");
const ItemDetail = () => import("../../views/ItemDetail.vue");
const PromoCode = () => import("../../views/PromoCode.vue");
const BannerItems = () => import("../../views/BannerItems.vue");
const MasterSearch = () => import("../../views/MasterSearch.vue");
const CategoryList = () => import("../../views/CategoryList.vue");
// const Information = () =>
//     import ('../../views/Information.vue')
const QR = () => import("../../views/Qr.vue");
const Feedback = () => import("../../views/Feedback.vue");
const BrandsItems = () => import("../../views/BrandsItems.vue");

const restaurant = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "landing-page",
    name: "landing-page",
    component: Front
  },
  {
    path: "vendor/:restaurant_id",
    name: "vendor",
    component: ItemDetail,
  },
  {
    path: "restaurant/:restaurant_id",
    name: "restaurant",
    component: ItemDetail,
  },
  {
    path: "promo-code",
    name: "promo-code",
    component: PromoCode,
  },
  {
    path: "banner-items/:banner_id",
    name: "banner-items",
    component: BannerItems,
  },
  {
    path: "search",
    name: "search",
    component: MasterSearch,
  },
  {
    path: "category/:category_id",
    name: "category",
    component: CategoryList,
  },
  // {
  //     path: 'details',
  //     name: 'details',
  //     component: Information
  // },
  {
    path: "qr/:restaurantid",
    name: "qr",
    component: QR,
  },
  {
    path: "feedback/:restaurantid",
    name: "feedback",
    component: Feedback,
  },
  {
    path: "brand-items/:brand_id",
    name: "brand-items",
    component: BrandsItems,
  },
];

export default restaurant;
